import React from 'react'
import Quote from '@/components/Quote'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useContentInProp } from '@/hooks/useContentInProp'

const QuoteContainer: React.FC = () => {
  const inProp = useContentInProp()
  const { t } = useTranslation()

  const quote = {
    name: t('our-platform.one_quote_title'),
    text: t('our-platform.one_quote_body').split(/(?:\r\n|\r|\n)/g),
  }
  // TODO: Use text var

  return (
    <Quote variant="light" name={quote.name} maxWidth="55rem" inProp={inProp}>
      One lean and mean code base <br />
      One version <br />
      One fully redundant infrastructure <br />
      One action-packed API
      <br />
    </Quote>
  )
}

QuoteContainer.propTypes = {}

QuoteContainer.defaultProps = {}

export default QuoteContainer
